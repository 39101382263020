import Vue from 'vue'
import App from './App.vue'
import { Card } from 'element-ui';
Vue.config.productionTip = false

Vue.component(Card.name, Card);

new Vue({
  render: h => h(App),
}).$mount('#app')
