<template>
  <div class="Timer" >
    <el-card class="box-card">
      <div  class="text item">
       距离明天还有
      </div>
      <div  class="text item">
        <span>{{ hour }}</span>小时<span>{{ minute }}</span>分钟<span>{{ second }}</span>秒钟
      </div>
    </el-card>

  </div>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export default {
  name: 'TimerCoponent',

  data() {
    return {
      hour: null,
      minute: null,
      second: null,
      diff:null
    }
  },
  mounted() {
    this.diffTime()
  },
  methods: {
    diffTime() {
      setInterval(() => {
        let dayNow = dayjs()
        let dayTomorrow = dayjs().add('1', 'day').hour(0).minute(0).second(0)
        let diff = dayjs.duration(dayTomorrow - dayNow)
        this.diff=diff
        let hour=diff.hours().toString()
        this.hour = hour.length==1?0+hour:hour
        let minute = diff.minutes().toString()
        this.minute = minute.length==1?0+minute:minute
        let second = diff.seconds().toString()
        this.second = second.length==1?0+second:second
      })
    }
  }
};
</script>

<style scoped lang="scss">
body{
}
.text {
  font-size: 80px;
  color: lightgray;
}

.box-card {
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  width: 100%;
}
.Timer{
  display: flex;
  justify-content: center;


}
span{
  display: inline-block;
  width: 100px;
  text-align: center;
}
</style>
